import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";

import workflowList from "../images/workflow-list.png";
import workflowView from "../images/workflow-view.png";

import { HeaderSection, SectionBreak, InfoSection } from "../components/sections";

const LoginPage = () => (
    <Layout>
        <SEO title="Automate Repetitive Analyses" />
        <HeaderSection
            title="Automate Repetitive Analyses"
            subtitle="Create, reuse, edit, and share pipelines as repeatable workflows."
        />
        <SectionBreak
            title="Convert Exploratory Testing Into a Repeatable Analysis"
            subtitle="Workflows allow knitting of multiple tools into a composite pipeline that can be executed as a single tool."
        />
        <InfoSection
            image={workflowView}
            facts={[
                {
                    title: "Use Workflows",
                    blurb: "Galaxy Pro offers a number of high-quality, extensible workflows for primary and secondary analysis, including QC, RNA-Seq, ChIP-Seq, and Variant Analysis.",
                },
                {
                    title: "Create a Workflow",
                    blurb: "Anyone can create a workflow through a graphical drag-and-drop interface by linking individual tools. Galaxy Pro ensures tool compatibility so you know you're doing it correctly.",
                },
                {
                    title: "Extract a Workflow",
                    blurb: "If you have already done an analysis interactively, you can Extract a Workflow from your existing History with a single click.",
                },
            ]}
        />
        <SectionBreak title="Learn and Reuse" subtitle="Workflows represent a knowledge base of your team." />
        <InfoSection
            image={workflowList}
            imageOnLeft={false}
            facts={[
                {
                    title: "Use Sub-Workflows",
                    blurb: "Sub-Workflows can be embedded into other workflows to avoid having to repeatedly build the same steps.",
                },
                {
                    title: "Easily Share Your Workflows",
                    blurb: "A workflow can be shared with specific users or published for everyone. You can also download or import an existing workflow to build on it.",
                },
                {
                    title: "Share Knowledge Across the Team",
                    blurb: "Shared workflows can be extended or adjusted to better match the goals of a new project. They can be used as a training tool to bring new members up to speed faster.",
                },
            ]}
        />
    </Layout>
);

export default LoginPage;
